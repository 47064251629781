<template>
  <section class="text-left mx-auto prose md:prose-xl px-6 py-4 pb-36 relative">
    <div id="information-container">
      <h1>Informasi Pendaftaran</h1>
      Informasi pendaftaran peserta didik baru dapat dipantau melalui pengumuman
      ini.
    </div>
  </section>
</template>

<script>
import marked from "marked";
import DOMPurify from "dompurify";

export default {
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementById(
        "information-container"
      ).innerHTML = DOMPurify.sanitize(marked(this.content));
    });
  },
};
</script>